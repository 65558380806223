import {getApiUrl} from "./APIconfig";
import {doGet, doPost} from "./FetchWrapper";

export const recoverPassword = email => {
    const url = `${getApiUrl()}/api/public/auth/recovery/pwd`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(email),
        credentials: 'include'
    };
    return fetch(url, requestOptions).then(result => result.json());
    
};

export const updatePassword = passwords => {
    const url = `${getApiUrl()}/api/public/auth/update/pwd`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(passwords),
        credentials: 'include'
    };
    return fetch(url, requestOptions).then(result => result.json());
};

export const checkIfValid = token => {
    const url = `${getApiUrl()}/api/public/auth/check/token`;
    const requestOptions = {
        method: 'POST',
        body: token,
        credentials: 'include'
    };
    return fetch(url, requestOptions).then(result => result.json());
};

export const login = values => {
    return doPost(`/api/public/auth`, values)
};

export const logout = values => {
    return doPost(`/api/public/auth/logout`, values)
};


export const registerPatient = values => {
    return doPost(`/api/public/register/patient`, values)
};

export const registerUser = values => {
    return doPost(`/api/public/register/user`, values)
};

export const refresh = () => {
    const url = `${getApiUrl()}/api/public/auth/refresh`;
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    
    };
    return fetch(url, requestOptions);
    
};

export const checkPincode = (body) => {
    return doPost('/api/public/auth/check-pincode', body)
};

export const resendPincode = (sendTo) => {
    return doPost('/api/public/auth/resend/pincode', {sendTo})
};
