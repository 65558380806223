import {
    Box,
    IconButton,
     SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {Edit as EditIcon} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import Moment from "react-moment";
import Circle from "../../../components/Circle";

const AssignedRequestTable = ({requests}) => {
    
    return (
        <>
            
            <PerfectScrollbar>
                <Box minWidth={700}>
                    {requests?.length ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>Paziente</TableCell>
                                        <TableCell>Servizio</TableCell>
                                        <TableCell>Descrizione</TableCell>
                                        <TableCell>Stato</TableCell>
                                        <TableCell>Scadenza</TableCell>
                                        <TableCell align='right'>Completata</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requests.map(request => {
                                        
                                        return (
                                            <TableRow
                                                hover
                                                key={request.id}
                                            >
                                                <TableCell  align='center'>
                                                    <Circle color={request.status.color}/>
                                                </TableCell>
                                                <TableCell>
                                                    {request?.request.patient?.name} {request.request?.patient?.surname}
                                                </TableCell>
                                                <TableCell>
                                                    {request.request?.service?.name}
                                                </TableCell>
                                                <TableCell>
                                                    {request.description}
                                                </TableCell>
                                                <TableCell>
                                                    {request.status?.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Moment format='DD/MM/YYYY HH:mm'>
                                                        {request.deadline}
                                                    </Moment>
                                                </TableCell>
                                                
                                                <TableCell align='right'>
                                                    <IconButton
                                                        component={RouterLink}
                                                        to={`/app/assigned/requests/${request.id}` }
                                                    >
                                                        <SvgIcon fontSize='small'>
                                                            <EditIcon />
                                                        </SvgIcon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        
                        ) :
                        <Box ml={5}>
                            <Typography>
                                Report non disponibili
                            </Typography>
                        </Box>
                    }
                
                
                </Box>
            </PerfectScrollbar>
        </>
    );
};

export default AssignedRequestTable;
