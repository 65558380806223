import React from 'react';
import {Box, Button, Grid, Link} from '@material-ui/core';
import Results from './Results';

import PageHeader from 'src/components/PageHeader';
import {Link as RouterLink} from "react-router-dom";

const RequestView = ({assigned}) => {
    
    return (
        <PageHeader title={'Request'} pageId={assigned ? 'assignedRequest.list' : 'request.list'}>
            {!assigned &&
                <Grid container justifyContent="flex-end">
                    <Link color='inherit'
                          component={RouterLink}
                          to='/app/requests/create'>
                        <Button variant='contained' color='secondary'>Nuova richiesta</Button>
                    </Link>
                </Grid>}
            <Box mt={3}>
                <Results assigned={assigned}/>
            </Box>
        </PageHeader>
    );
};

export default RequestView;
