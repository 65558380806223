import { getKeys } from '../utils/APIUtil';
import {doGet, doPost}  from './FetchWrapper';

const search = args  => {
    return doGet(`/api/?event=question.list${getKeys(args)}`)
};

const get = id => {
    return doGet(`/api/?event=question.get&id=${id}`)
};

const modify = data => {
    return doPost(`/api/?event=question.modify`, data)
}

const create = data => {
    return doPost(`/api/?event=question.create`, data)
}

export const remove = ids => {
    return doPost(`/api/?event=question.delete`, ids)

};

export const saveOrders = ids => {
    return doPost(`/api/?event=question.saveOrders`, ids)
};

export const listPrevQuestions = ()  => {
    window.open(`/listPrevQuestions.cfm?token=8441787694`, '_blank')
};

const listAnamnesis = () => {
    return doGet(`/api/?event=question.listAnamnesis`)
};

export { search, get,  modify, create, listAnamnesis };
