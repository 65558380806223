import {API} from "../config";
import {getApiUrl} from "./APIconfig";


export const getVersion = async () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    };
    const response = await fetch(`${getApiUrl()}/api/public/version`, requestOptions)
    return await response.json()
    
};
