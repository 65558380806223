import * as auth from 'src/services/AuthService';
import {getApiUrl} from "./APIconfig";

export const doGet = url => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    };
    return fetch(`${getApiUrl()}${url}&uid=${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`, requestOptions)
        .then(result => handleGetResponse(result, url));
};

export const doPost = (url, body) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: 'include'
        };
        return fetch(`${getApiUrl()}${url}`, requestOptions)
            .then(result => handleResponse(result, url, body));
    } catch(e) {
        console.log("error", e)
    }
};

export const doGetFile = async url => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    };
    await auth.refresh();
    return fetch(`${getApiUrl()}${url}`, requestOptions)
        .then(res => res.blob())
        .then(blob => {
            const file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
        });
};

export const handleResponse = async (result, url, body) => {
    if (result.status === 401) {
        const refresh = await auth.refresh();
        if (refresh.status === 401) {
            localStorage.removeItem('user');
            window.location.href = '/login';
        } else {
            return doPost(url, body)
        }
    }
    return result.json();
}

export const handleGetResponse = async (result, url) => {
    if (result.status === 401) {
        const refresh = await auth.refresh();
        if (refresh.status === 401) {
            localStorage.removeItem('user');
            window.location.href = '/login';
        } else {
            return doGet(url)
        }
    }
    return result.json();
}
