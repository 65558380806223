import Header from '../../layouts/DashboardLayout/Header';
import React from 'react';
import Page from '../Page';
import { Container } from '@material-ui/core';
import { useStyles } from '../ListView/style';
import getPage from '../../services/PageService';

const PageHeader = ({pageId, title, children }) => {
    
    let page = getPage( pageId );
    
    const classes = useStyles();
    
    return (
        <Page className={classes.root} title={title}>
            <Container maxWidth={false}>
                <Header title={page.title} breadcrumbs={page.breadcrumbs} />
                {children}
            </Container>
        </Page>
    );
};

export default PageHeader
