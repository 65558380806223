import { getKeys,  } from '../utils/APIUtil';
import {doGet, doPost}  from './FetchWrapper';


export const call = values => {
    return doPost(`/api/?event=call.callPatient`, values)
};

export const accept = values => {
    return doPost(`/api/?event=call.accept`, values)
};

export const setCallAsReceived = values => {
    return doPost(`/api/?event=call.setCallAsReceived`, values)
};

export const refuse = values => {
    return doPost(`/api/?event=call.refuse`, values)
};


