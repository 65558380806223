import React, {lazy} from "react";
import {paths, VIEWACCESS} from "../constants";
import Guard from "../guards/Guard";
import {Redirect} from "react-router-dom";


export default {
    path: '/app/management/',
    routes: [
        {
            exact: true,
            path: `${paths.patient}`,
            guard: Guard,
            access: VIEWACCESS.patient.visualization,
            component: lazy(() =>
                import('src/views/patient/PatientListView')
            )
        },
        {
            exact: true,
            guard: Guard,
            access: VIEWACCESS.patient.management,
            path: '/app/management/telemedicine',
            component: lazy(() =>
                import('src/views/patient/PatientTelemedicineView')
            )
        },        
        {
            exact: true,
            // Fix per B7 -> redirect pagina 404 a medico specialista sul dettaglio paziente
            //guard: Guard,
            access: VIEWACCESS.patient.management,
            path: [
                `${paths.patient}/create`,
                `${paths.patient}/:patientId`,
                `${paths.patient}/:patientId/:mode`,
                `${paths.patient}/:patientId/:mode/:val`,
            ],
            component: lazy(() =>
                import('src/views/patient/PatientEditView')
            )
        },
        {
            exact: true,
            guard: Guard,
            access: VIEWACCESS.exam.visualization,
            path: `${paths.exam}`,
            component: lazy(() => import('src/views/exam/ExamListView'))
        },
        {
            exact: true,
            guard: Guard,
            access: VIEWACCESS.exam.visualization,
            path: [
                `${paths.exam}/create`,
                `${paths.exam}/:examId`,
                `${paths.exam}/:examId/:mode`
            ],
            component: lazy(() => import('src/views/exam/ExamEditView'))
        },
        {
            exact: true,
            path: `${paths.hufi}`,
            guard: Guard,
            access: VIEWACCESS.exam.visualization,
            component: lazy(() => import('src/views/hufi/HufiListView'))
        },

        {
            exact: true,
            path: [
                `${paths.hufi}/create`,
                `${paths.hufi}/:hufiId`,
                `${paths.hufi}/:hufiId/:value`
            ],
            guard: Guard,
            access: VIEWACCESS.exam.visualization,
            component: lazy(() => import('src/views/hufi/HufiEditView'))
        },
        {
            exact: true,
            path: '/app/management',
            component: () => <Redirect to='/app/management/patients'/>
        },
    ]
}


