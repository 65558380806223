import React, {useEffect, useState} from "react";
import { Button, Dialog, DialogActions,  DialogTitle } from "@material-ui/core";
import * as versionAPI from "./services/VersionService";

const VersionControl = () => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        checkVersion()
        const intervalId = setInterval(() => checkVersion(), 300000)
        return () => clearInterval(intervalId);
    }, [])

    const checkVersion = async () => {
        try {
            const result = await versionAPI.getVersion()
            const version = result.data
            if (version !== localStorage.getItem('version')) {
                setOpen(true)
            }
            localStorage.setItem('version', version)
        } catch (e) {
            console.log(e)
        }
    }

    const reload = () => {
        window.location.reload()
    }
    
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'md'}
            keepMounted
        >
            <DialogTitle>
                Una nuova versione è disponibile
            </DialogTitle>
        
            <DialogActions>
                <Button
                    color={'secondary'}
                    variant='contained'
                    onClick={reload}
                >
                    Clicca qui per caricarla
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VersionControl;
