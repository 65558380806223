import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

// TODO : modificare permessi dei vari utenti per accesso alle pagine
const Guard = ({access, children}) => {
    const {user} = useAuth();
    if (user.role.id !== 'ADM') {
        if (!user.sections[access]) return <Redirect to="/404"/>;
    }

    return (
        <>
            {children}
        </>
    );
};

Guard.propTypes = {
    access: PropTypes.string,
    children: PropTypes.node
};

export default Guard;
