import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import {ROLES} from "../constants";

export const checkRole = role => {
    return [ROLES.admin].includes(role?.id);
}

const AdminGuard = ({ children }) => {
    const { user } = useAuth();
    if ( !checkRole(user.role) ) {
        return <Redirect to="/404" />;
    }
    
    return (
        <>
            {children}
        </>
    );
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
