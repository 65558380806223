import React from 'react';
import PropTypes from 'prop-types';
import useAuth from "../hooks/useAuth";
import {Redirect} from "react-router-dom";
import {getDefaultUrl} from "../utils";

const GuestGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth();

    if (isAuthenticated) {
        return user && <Redirect to={getDefaultUrl(user.role.id)} />;
    }

    return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
