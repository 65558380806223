import React, { forwardRef, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {appConfig as config, clientDomain} from 'src/config';
import {DOMAIN_PROPERTIES} from "../constants";

//import track from 'src/utils/analytics';

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {

    //console.log("props:title", title);
    //return;

    //const location = useLocation();

    const name =  DOMAIN_PROPERTIES[window.location.host].title;
    
    const sendPageViewEvent = useCallback(() => {
    /*
    track.pageview({
      page_path: location.pathname
    });
    */
    }, []);
 
    /*
    useEffect(() => {
        sendPageViewEvent();
    }, [sendPageViewEvent]);
    */

    return (
        <div ref={ref} {...rest}>
            
            <Helmet>
                <title>{title} - {name}</title>
            </Helmet>
            {children}
        </div>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;
