import React, {
    useState,
    useEffect
} from 'react';
import {
    Box,
    Button,
    Paper,
    Portal,
    Typography,
     Dialog,
    DialogContent
} from '@material-ui/core';
import {ROLES, THEMES} from 'src/constants';
import Pusher from "pusher-js";
import {pusherAPI} from "../../config";
import useAuth from "../../hooks/useAuth";
import * as service from 'src/services/CallService'
import useSound from 'use-sound';


const PatientCall = () => {
    const [isOpen, setOpen] = useState(false);
    const [play, {stop}] = useSound('/static/sounds/jingle.wav');
    const {user} = useAuth();
    const [call, setCall] = useState(null);

    useEffect(() => {

        if (user?.role?.id !== ROLES.patient) return;

        const pusher = new Pusher(pusherAPI.key, {
            cluster: pusherAPI.cluster
        })
        const channel = pusher.subscribe(`call.patient.${user.id}`);
        channel.bind('call.initialized', (data) => {
            handleCall(data);

            // Code that runs when channel listens to a new message
        })

        return (() => {
            pusher.unsubscribe(`call.patient.${user.id}`);
            stop();
        })
    }, [play])

    const handleCall = async (data) => {
        await service.setCallAsReceived({'id': data.id})
        play();
        setCall(data);
        setOpen(true);

    }

    const handleRefuse = async () => {
        stop()
        setOpen(false);
        await service.refuse({"id": call?.id})
    };
    const handleAccept = async () => {
        stop()
        setOpen(false);
        await service.accept({"id": call?.id})
        setTimeout(() => {window.open(call?.user?.meetLink, '_blank');}, 500)
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
        >
            <DialogContent
                elevation={3}
            >
                <Typography
                    variant='h2'
                    color='textPrimary'
                    gutterBottom
                >
                    Chiamata in arrivo
                </Typography>
                <Typography
                    variant='h3'
                    color='textSecondary'
                >
                    Stai ricevendo una chiamata dall'operatore {call?.user?.name} {call?.user?.surname}
                </Typography>
                <Box
                    mt={2}
                    display='flex'
                    justifyContent='space-between'
                >
                    <Button
                        style={{
                            backgroundColor: 'green',
                            height: '5rem',
                            width: '24rem'
                        }}
                        onClick={handleAccept}
                    >
                        Accetta
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "red",
                            height: '5rem',
                            width: '24rem'
                        }}
                        onClick={handleRefuse}
                    >
                        Rifiuta
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default PatientCall;
