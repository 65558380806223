import { boolean } from "yup";

export const getKeys = (args) => {
    const keys = [];
    
    Object.keys(args).forEach((key) => {
        var value = args[key];

        if (value || typeof value == "boolean"){
            keys.push(`&${key}=${args[key]}`);
        }

    });

    return keys.join('');
};


export const removeUnusedKeys = (obj, keys) => {
    let curObj = obj;
    keys.map(key => {
        if (getObjValue(curObj, key).length === 0 || getObjValue(curObj, key) < 1) {
            Object.keys(curObj).map(curKey =>{
                if (curKey === key.split('.')[0]) delete curObj[curKey];
            })
        }
    });
    return curObj;
};


const getObjValue = (obj, path) => {
    if (path.indexOf('.')) {
        const subs = path.split('.');
        let ret = obj;
        for (let i = 0; i < subs.length; i++) {
            ret = ret[subs[i]];
        }
        return ret;
    } else {
        return obj[path];
    }
};

export const getResult = (response ) => {
    return response
        .json()
        .then(data => {
            return data;
        })
        .catch(err => {
            console.log(err);
        });
}
