import React from 'react';
import { AuthProvider } from '../../contexts/JWTAuthContext';
import { ConstantsProvider } from '../../contexts/ConstantsContext';

const combineComponents = (...components) => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({ children }) => <>{children}</>,
    );
};

const providers = [
   AuthProvider,
    ConstantsProvider,
]

const AppContextProvider = combineComponents(...providers);

export default AppContextProvider;
