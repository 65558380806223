export const setSelected = (selected, setSelected, selectId) => {
    if (!selected.includes(selectId)) {
        setSelected(prevSelected => [...prevSelected, selectId]);
    } else {
        setSelected(prevSelected =>
            prevSelected.filter(id => id !== selectId)
        );
    }
};

export const deleteSelected = async (service, selected, setDeleteSuccess, setOutcome, reload, setSelected) => {
    const result = await service.remove(selected);
    if (result.status === 'ERROR') {
        setDeleteSuccess('N');
        setOutcome(result);
    } else {
        await reload();
        setDeleteSuccess('Y');
    }
    setTimeout(() => setDeleteSuccess(''), 2000);
    
    setSelected([]);
}
