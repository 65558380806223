
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const appConfig = {
    name: 'SmartVillage',
    mosaicoName: 'Mosaico'
};

export const clientDomain = {
    smartvillage: process.env.REACT_APP_SMARTVILLAGE,
    mosaico: process.env.REACT_APP_MOSAICO,
}

export const API = {
    smartvillage: process.env.REACT_APP_API_URL_SMARTVILLAGE,
    mosaico: process.env.REACT_APP_API_URL_MOSAICO,
};

export const pusherAPI = {
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
}
