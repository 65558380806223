import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Breadcrumbs,
    Link,
    Typography,
    makeStyles,
    Box
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
    root: {}
}));

const createItem = ( item ) =>{

    let index = 0;

    if ( item.type === 'Link' ) {
        return (
            <Link
                key={index}
                variant="body1"
                color="inherit"
                to={item.to}
                component={RouterLink}
            >
                {item.text}
            </Link>
        )
    }

    if ( item.type === 'Typography' ) {
        return (
            <Typography
                key={index}
                variant="body1"
                color="textPrimary"
            >
                {item.text}
            </Typography>
        )
    }

    index++;

}

const Header = ({ title, breadcrumbs, className, ...rest }) => {

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >

                { breadcrumbs?.map(( value, index ) => {
                    return createItem( value, index );
                }) }

            </Breadcrumbs>

            <Box my={2}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                {title}
                </Typography>
            </Box>

        </div>

    );
};

Header.propTypes = {
    className: PropTypes.string,
    page: PropTypes.string
};

export default Header;
